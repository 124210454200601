import { useRef } from 'react';

function Contact() {
  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);

    const userName = formData.get('user_name');
    const userEmail = formData.get('user_email');
    const message = formData.get('message');

    if (!userName || !userEmail || !message) {
      alert('Please fill in all fields before sending the message.');
      return;
    }

    const requestData = {
      user_name: userName,
      user_email: userEmail,
      message: message,
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestData),
    };

    try {
      const response = await fetch('https://gxsxi3jlme.execute-api.us-east-1.amazonaws.com/s1/contact', requestOptions);
      const data = await response.json();
      console.log(data); // Handle the response from the backend as needed
      alert('Message sent, you will hear back from me soon');
      e.target.reset();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div id="contact" className="flex flex-col items-center mt-10 mb-10">
      <h2 className="text-3xl font-bold mb-5">Contact Me</h2>
      <form className="flex flex-col gap-4 w-full max-w-lg" ref={form} onSubmit={sendEmail}>
        <div>
          <label className="text-xl font-semibold" htmlFor="name">
            Name
          </label>
          <input
            className="w-full p-3 mt-1 rounded-md bg-gray-100 focus:outline-none focus:ring focus:ring-indigo-200"
            type="text"
            id="name"
            name="user_name"
            required
          />
        </div>
        <div>
          <label className="text-xl font-semibold" htmlFor="email">
            Email
          </label>
          <input
            className="w-full p-3 mt-1 rounded-md bg-gray-100 focus:outline-none focus:ring focus:ring-indigo-200"
            type="email"
            id="email"
            name="user_email"
            required
          />
        </div>
        <div>
          <label className="text-xl font-semibold" htmlFor="message">
            Your Message
          </label>
          <textarea
            className="w-full p-3 mt-1 h-32 rounded-md bg-gray-100 focus:outline-none focus:ring focus:ring-indigo-200"
            id="message"
            name="message"
            required
          ></textarea>
        </div>
        <button className="px-4 py-2 mt-5 bg-indigo-600 text-white rounded-md hover:bg-indigo-700">
          Send
        </button>
      </form>
      <div className="mt-10 text-sm text-gray-400">© 2023 Asme. All Rights Reserved.</div>
    </div>
  );
}

export default Contact;
